<template>
  <Parent>
    <template slot="content">
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-row align="center">
              <v-col cols="auto">{{ $t('notification_list') }}</v-col>

              <v-spacer></v-spacer>

              <v-col cols="3">
                <v-text-field
                  outlined
                  dense
                  v-on:keyup.13="searchPosts($event)"
                  :placeholder="`${$t('search')}...`"
                  hide-details
                >
                </v-text-field>
              </v-col>

              <v-col cols="auto">
                <v-btn
                  color="primary"
                  text
                  class="text-capitalize font-weight-regular"
                  to="/notification/create"
                >
                  <v-icon left>mdi-plus</v-icon>
                  {{ $t('page_notifications_draft_create_new_text') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text class="px-0">
            <Table
              v-if="pagination && notifications"
              :headers="headers"
              :items="notifications"
              :loading="loading"
              :total-records="pagination.records_total"
              :number-of-pages="pagination.total_pages"
              @click:row="goToEdit"
            >
              <template v-slot:item.id="{ item }">
                <span class="primary--text">{{ item.id }}</span>
              </template>

              <template v-slot:item.status="{ item }">
                <span
                  class="text-capitalize"
                  :class="'text-status-' + item.status"
                  >{{ item.status }}</span
                >
              </template>

              <template v-slot:item.type="{ item }">
                <span
                  v-if="item.type"
                  class="text-capitalize"
                  :class="
                    item.type.id === 'important' ? 'text-type-important' : ''
                  "
                  >{{ item.type.name }}</span
                >
              </template>

              <template v-slot:item.category="{ item }">
                <span class="text-capitalize">{{ item.category.name }}</span>
              </template>

              <template v-slot:item.type="{ item }">
                <span class="text-capitalize">{{ item.type.name }}</span>
              </template>

              <template v-slot:item.created_at="{ item }">
                <span class="text-capitalize">{{ item.created_at }}</span>
              </template>

              <template v-slot:item.tags="{ item }">
                {{ Array.prototype.map.call(item.tags, s => s.name).join(", ") }}
              </template>

              <template v-slot:item.view="{ item }">
                {{ item.view.toLocaleString() }}
              </template>

              <template v-slot:item.actions="{ item }">
                <v-btn
                  icon
                  color="secondary"
                  text
                  :to="`/notification/${item.id}/edit`"
                >
                  <v-icon size="12">$edit</v-icon>
                </v-btn>
              </template>
            </Table>
          </v-card-text>
        </v-card>
      </v-col>
    </template>
  </Parent>
</template>

<script>
import Parent from '@/views/admin/Notification/index'
import Table from '@/components/admin/partials/Table/Table'

export default {
  name: 'index',
  components: { Table, Parent },
  created() {
    this.getDataFromApi()
  },

  computed: {
    notifications() {
      return this.$store.getters.allNotifications
    },
    pagination() {
      return this.$store.getters.notificationPagination
    }
  },

  data() {
    return {
      headers: [
        { text: this.$t('id'), align: 'center', value: 'id' },
        {
          text: this.$t('page_notifications_draft_table_header_title'),
          align: 'center',
          value: 'title'
        },
        {
          text: this.$t('page_notifications_draft_table_header_type'),
          align: 'center',
          value: 'type',
          sortable: false
        },
        {
          text: this.$t('page_notifications_draft_table_header_category'),
          align: 'center',
          value: 'category'
        },
        {
          text: this.$t('page_notifications_draft_table_header_tags'),
          align: 'center',
          value: 'tags',
          sortable: false
        },
        {
          text: this.$t('page_notifications_draft_table_header_view'),
          align: 'center',
          value: 'user_notification_read_count'
        },
        {
          text: this.$t('page_notifications_draft_table_header_created'),
          align: 'center',
          value: 'created_at'
        },
        {
          text: this.$t('actions'),
          align: 'center',
          value: 'actions',
          sortable: false
        }
      ],
      loading: false
    }
  },

  methods: {
    async getDataFromApi() {
      this.loading = true
      let data = {}
      data.is_draft = '1'
      data.sort = 'id'
      data.order = 'desc'
      await this.$store.dispatch('GET_ALL_NOTIFICATIONS', data)
      this.loading = false
    },
    searchPosts(event) {
      let data = {}
      data.search = event.currentTarget.value
      data.is_draft = '1'
      this.$store.dispatch('GET_ALL_NOTIFICATIONS', data)
    },
    goToEdit(item) {
      this.$router.push({
        name: 'NotificationEdit',
        params: {
          id: item.id
        }
      })
    }
  }
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
